import React from "react";
import PropTypes from 'prop-types';
import './message-box-with-image.scss';

export const MESSAGE_BOX_IMAGE_VARIANTS = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
}
/**
 * General message box that is displayed with an image, a title beneath the image, and the message at the end. It is laid out vertically by default
 * but can be made horizontal (see propTypes) too.
 * Generally used to display empty states
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @return {JSX.Element}
 */
const MessageBoxWithImage = (props) => {

    return (
        <div className={`message-box-with-image -${props.direction} ${props.messageBoxClasses}`}>
            <img height={200} width={200} className={`message-box-image ${props.imgClasses} -${props.imgVariant}`} src={props.imageSrc} alt={props.message}/>
            <div className={'message-box-text-container'}>
                {props.heading && <h4 className={`message-box-heading ${props.headingClasses}`}> {props.heading}</h4>}
                <p className={`message-box-body-text ${props.messageClasses}`}> {props.message} </p>
            </div>
        </div>
    );
}


MessageBoxWithImage.propTypes = {
    /**
     * The url/image src for the image displayed
     */
    imageSrc: PropTypes.string.isRequired,
    /**
     * the message to display at the bottom of the container
     */
    message: PropTypes.string.isRequired,
    /**
     * any classes to apply to the element displaying the message. Typical usage: typography classes
     */
    messageClasses: PropTypes.string,
    /**
     * Any heading to display before the message. This is optional
     */
    heading: PropTypes.string,

    /**
     * any classes to give to the heading. Typical usage: typography classes
     */
    headingClasses: PropTypes.string,

    /**
     * classes to give to the image
     */
    imgClasses: PropTypes.string,

    /**
     * variant for the image. One of MESSAGE_BOX_IMAGE_VARIANTS.* constants. Default: medium
     */
    imgVariant: PropTypes.string,

    /**
     * any classes to give to the root level element
     */
    messageBoxClasses: PropTypes.string,

    /**
     * the direction of the container, can be vertical (default) or can stack the elements horizontally
     */
    direction: PropTypes.string
}

MessageBoxWithImage.defaultProps = {
    messageBoxClasses: '',
    imgClasses: '',
    headingClasses: '',
    messageClasses: '',
    heading: '',
    imgVariant: MESSAGE_BOX_IMAGE_VARIANTS.MEDIUM,
    direction: 'vertical',
}

export default MessageBoxWithImage;
import {useEffect} from 'react';
import {
  areMoreLazyLoadingEntriesNeeded,
  DEFAULT_NUM_ROWS,
  filterEventEntriesForDate,
  filterScheduledEntriesForDate,
  getCalendarEndDate,
  getCalendarStartDate,
  getEmptyEntriesObject,
  getEntriesMapKey,
  showEventsIntroPopupIfNeeded,
} from '@Libraries/content-planner-library';
import {
  setCustomEventFormDescription,
  setCustomEventFormTimestamp,
  setCustomEventFormTitle,
  updateEventRegionFilters,
  updateEventReligionFilters,
} from '@Components/content-planner/content-planner-reducer';
import {getEntriesForContentPlanner} from '@Components/content-planner/content-planner-thunk';
import {areDatesEqual, getDateFromUnixTimestamp, getDayMonthYearFormat, getUnixTimestamp} from '@Utils/date.util';
import type {
  BaseEvent,
  ContentPlannerEntries,
  ContentPlannerFilters,
  ContentPlannerState,
  ContentPlannerThunkParams,
  CustomEventFormSetters,
  EntriesMap,
  EntryTypeFilter,
  EventAreaFiltersFromStore,
  SocialGlobalEventFilter} from '@Components/content-planner/content-planner.types';
import {
  EventType
} from '@Components/content-planner/content-planner.types';
import type {FiltersHashmap} from '@Components/checkbox-filters/checkbox-filters.types';
import {noop} from '@Utils/general.util';
import {arraysEqual} from '@Utils/array.util';
import {useAppDispatch, useAppSelector} from '@/hooks';

export const useContentPlannerDate = (): Date => {
  return useAppSelector((state) => {
    return state.contentPlanner.date;
  }, areContentPlannerDatesTheSame);
};

export const useEntriesMapKey = (): string => {
  const contentPlannerDate = useContentPlannerDate();
  return getEntriesMapKey(contentPlannerDate);
};

export const useContentPlannerActiveDate = (): Date => {
  return useAppSelector((state) => {
    return state.contentPlanner.activeDate;
  }, areContentPlannerDatesTheSame);
};

export const useIsActiveContentPlannerDate = (date: Date): boolean => {
  return useAppSelector((state) => {
    return areDatesEqual(state.contentPlanner.activeDate, date);
  });
};

export const useContentPlannerFilters = (): ContentPlannerFilters => {
  return useAppSelector((state) => {
    return state.contentPlanner.contentPlannerFilters;
  }, areFiltersTheSame);
};

export const useContentPlannerEntryFilters = (): EntryTypeFilter => {
  return useAppSelector((state) => {
    return state.contentPlanner.contentPlannerFilters.entryTypeFilters;
  });
};

export const useSocialGlobalEventFilters = (): SocialGlobalEventFilter => {
  return useAppSelector((state) => {
    return state.contentPlanner.contentPlannerFilters.socialGlobalEventFilters;
  });
};

export const useEventRegionFilters = (): EventAreaFiltersFromStore => {
  const filters = useAppSelector((state) => {
    return state.contentPlanner.contentPlannerFilters.eventRegionFilters;
  });
  const filtersSource = useAppSelector((state) => {
    return state.contentPlanner.regions;
  });

  const dispatch = useAppDispatch();
  const onChange = (newFiltersState: FiltersHashmap): void => {
    dispatch(updateEventRegionFilters(newFiltersState));
  };

  return {
    filters,
    filtersStateSetter: onChange,
    filtersSource,
  };
};

export const useEventReligionFilters = (): EventAreaFiltersFromStore => {
  const filters = useAppSelector((state) => {
    return state.contentPlanner.contentPlannerFilters.eventReligionFilters;
  });
  const filtersSource = useAppSelector((state) => {
    return state.contentPlanner.religions;
  });

  const dispatch = useAppDispatch();
  const onChange = (newFiltersState: FiltersHashmap): void => {
    dispatch(updateEventReligionFilters(newFiltersState));
  };

  return {
    filters,
    filtersStateSetter: onChange,
    filtersSource,
  };
};

export const useEventAreaFilters = (filterOfEventType: EventType): EventAreaFiltersFromStore => {
  const isRegional = filterOfEventType === EventType.REGIONAL;
  const isReligious = filterOfEventType === EventType.RELIGIOUS;

  if (!isRegional && !isReligious) {
    throw new Error(`invalid type ${filterOfEventType} provided`);
  }

  if (isRegional) {
    return useEventRegionFilters();
  }

  if (isReligious) {
    return useEventReligionFilters();
  }

  return {
    filters: {},
    filtersSource: [],
    filtersStateSetter: noop,
  };
};

export const useCustomEventFormData = (): BaseEvent => {
  return useAppSelector((state) => {
    return state.contentPlanner.customEventFormData;
  });
};

export const useIsNewEventBeingCreatedForDate = (date: Date): boolean => {
  return useAppSelector((state) => {
    return state.contentPlanner.isNewEventBeingCreated && areDatesEqual(date, getDateFromUnixTimestamp(state.contentPlanner.customEventFormData.timestamp));
  });
};

export const useCustomEventFormSetters = (): CustomEventFormSetters => {
  const dispatch = useAppDispatch();
  const onTitleChange = (newTitle: string): void => {
    dispatch(setCustomEventFormTitle(newTitle));
  };

  const onDescriptionChange = (newDescription: string): void => {
    dispatch(setCustomEventFormDescription(newDescription));
  };

  const onDateChange = (newDate: Date): void => {
    dispatch(setCustomEventFormTimestamp(getUnixTimestamp(newDate)));
  };

  return {onTitleChange, onDescriptionChange, onDateChange};
};

export const useAreEntriesPopulatedForCurrentPlannerDate = (): boolean => {
  const entriesMapKey = useEntriesMapKey();
  return useAppSelector((state) => {
    return state.contentPlanner.entriesMap[entriesMapKey]?.areEntriesPopulated ?? false;
  });
};

export const useContentPlannerEntriesMap = (): EntriesMap => {
  return useAppSelector((state) => {
    return state.contentPlanner.entriesMap;
  });
};

export const useContentPlannerEntriesForDate = (date: Date): ContentPlannerEntries => {
  return useAppSelector(({contentPlanner}) => {
    return getEntriesForDateFromStore(contentPlanner, date);
  }, areEntriesTheSame);
};

export const useFilteredContentPlannerEntriesForDate = (date: Date): ContentPlannerEntries => {
  const entriesForDate = useContentPlannerEntriesForDate(date);
  const filters = useContentPlannerFilters();

  return {
    eventEntries: filterEventEntriesForDate(entriesForDate.eventEntries, filters),
    scheduledEntries: filterScheduledEntriesForDate(entriesForDate.scheduledEntries, filters.entryTypeFilters),
  };
};

export const useLoadPlannerEntries = (): void => {
  const dispatch = useAppDispatch();
  const contentPlannerDate = useContentPlannerDate();
  const entriesMap = useContentPlannerEntriesMap();
  const entriesMapKey = useEntriesMapKey();

  const getContentPlannerEntries = (args: ContentPlannerThunkParams): void => {
    dispatch(getEntriesForContentPlanner(args))
      .then((reducer) => {
        const entryData = reducer.payload as ContentPlannerEntries;
        if (!entryData || entryData.lazyLoadingData === undefined) {
          return;
        }

        const {lazyLoadingData} = entryData;
        const {entryTypeLazyLoadData} = lazyLoadingData;

        if (!areMoreLazyLoadingEntriesNeeded(entryTypeLazyLoadData)) {
          return;
        }

        const lazyLoadArgs: ContentPlannerThunkParams = {
          fromTimestamp: parseInt(lazyLoadingData.dateFrom, 10),
          toTimestamp: parseInt(lazyLoadingData.dateTo, 10),
          lazyLoadingInfo: entryTypeLazyLoadData,
        };

        getContentPlannerEntries(lazyLoadArgs);
      })
      .catch(noop);
  };

  const getEntriesFromServer = (): void => {
    const args: ContentPlannerThunkParams = {
      fromTimestamp: getUnixTimestamp(getCalendarStartDate(contentPlannerDate)),
      toTimestamp: getUnixTimestamp(getCalendarEndDate(DEFAULT_NUM_ROWS, contentPlannerDate)),
    };
    getContentPlannerEntries(args);
  };

  useEffect(() => {
    if (entriesMap[entriesMapKey] !== undefined && entriesMap[entriesMapKey].areEntriesPopulated) {
      return;
    }

    getEntriesFromServer();
  }, [entriesMapKey]);
};
export const useInitContentPlanner = (): void => {
  useEffect(() => {
    showEventsIntroPopupIfNeeded();
  }, []);
};

export const useOpenEventTemplatesDialog = (): void => {
  const initTemplateDialog = (): void => {
    const eventHashedId = getEventHashedIdFromUrlHash();

    window.PMW.util.require(typeof window.PMW.contentPlannerEvents === 'undefined', 'eventTemplatesDialog', true, () => {
      if (eventHashedId) {
        window.PMW.contentPlannerEvents?.openEventTemplatesDialog(eventHashedId);
      } else {
        window.PMW.contentPlannerEvents?.closeEventTemplatesDialog();
      }
    });
  };

  useEffect(() => {
    initTemplateDialog();
  }, [window.location.hash]);
};

const getEntriesForDateFromStore = (contentPlannerState: ContentPlannerState, date: Date): ContentPlannerEntries => {
  const entriesMapKey = getEntriesMapKey(contentPlannerState.date);
  const formattedDate = getDayMonthYearFormat(date);

  if (contentPlannerState.entriesMap[entriesMapKey] === undefined || contentPlannerState.entriesMap[entriesMapKey].entriesMappedByDate[formattedDate] === undefined) {
    return getEmptyEntriesObject();
  }

  return contentPlannerState.entriesMap[entriesMapKey].entriesMappedByDate[formattedDate];
};

const areEntriesTheSame = (val1: ContentPlannerEntries, val2: ContentPlannerEntries): boolean => {
  return arraysEqual(val1.scheduledEntries, val2.scheduledEntries) && arraysEqual(val1.eventEntries, val2.eventEntries);
};

const areContentPlannerDatesTheSame = (date1: Date, date2: Date): boolean => {
  return areDatesEqual(date1, date2);
};

const areFiltersTheSame = (filters1: ContentPlannerFilters, filters2: ContentPlannerFilters): boolean => {
  const areSocialGlobalFiltersSame = arraysEqual(Object.values(filters1.socialGlobalEventFilters), Object.values(filters2.socialGlobalEventFilters));
  const areRegionalFiltersSame = arraysEqual(Object.values(filters1.eventRegionFilters), Object.values(filters2.eventRegionFilters));
  const areReligiousFiltersSame = arraysEqual(Object.values(filters1.eventReligionFilters), Object.values(filters2.eventReligionFilters));
  const areEntryTypeFiltersSame = arraysEqual(Object.values(filters1.entryTypeFilters), Object.values(filters2.entryTypeFilters));

  return areEntryTypeFiltersSame && areSocialGlobalFiltersSame && areRegionalFiltersSame && areReligiousFiltersSame;
};
const getEventHashedIdFromUrlHash = (): string | null => {
  const hashSegments = window.location.hash.split('/');

  if (hashSegments.length < 3) {
    return null;
  }

  return hashSegments[2];
};



import type {ListItemType1Storage} from '@Components/list/components/list-item-type-1';
import type {ListItemType2Storage} from '@Components/list/components/list-item-type-2';
import type {ListItemType3Storage} from '@Components/list/components/list-item-type-3';
import type {ListItemType4Storage} from '@Components/list/components/list-item-type-4';
import type {ListItemType5Storage} from '@Components/list/components/list-item-type-5';
import type {ListHeadingItemStorage} from '@Components/list/components/list-heading-item';
import type {ListItemTypeButtonStorage} from '@Components/list/components/list-item-type-button';
import type {ListItemType8Storage} from '@Components/list/components/list-item-type-8';

/**
 * Enums
 */

export enum LIST_ITEM_TYPE {
  HEADING = 'heading',
  DEFAULT_1 = 'default_type_1',
  DEFAULT_2 = 'default_type_2',
  DEFAULT_3 = 'default_type_3',
  DEFAULT_4 = 'default_type_4',
  DEFAULT_5 = 'default_type_5',
  DEFAULT_8 = 'default_type_8',
  BUTTON = 'button',
}

/**
 * Types
 */

export type ListItemStorage =
  | ListItemType1Storage
  | ListItemType2Storage
  | ListItemType3Storage
  | ListItemType4Storage
  | ListItemType5Storage
  | ListItemType8Storage
  | ListHeadingItemStorage
  | ListItemTypeButtonStorage;

export type SelectableListItems = ListItemType1Storage | ListItemType2Storage | ListItemType3Storage | ListItemType4Storage | ListItemType5Storage | ListItemType8Storage;

/**
 * Common Interfaces
 */

interface ListItemBaseStorage {
  id: string;
  type: LIST_ITEM_TYPE;
  className?: string;
}

export interface ListDefaultItemBaseStorage extends ListItemBaseStorage {
  text: string;
  isSelected?: boolean;

  onClick?(listId?: string, listItemId?: string): void;
  searchTerms?: Array<string>;
}

export interface ListDefaultSubItemBaseStorage {
  id: string;
  text: string;
  className?: string;

  onClick?(listItemId: string, subItemId: string): void;
}

import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {EmailMarketingCampaignPageType} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import type {EmailCampaignSortingFilter, EmailCampaignStatusFilter, EmailMarketingCampaignsReduxState, ToggleCampaignRenameModePayload} from '@Components/email-marketing-campaigns/email-marketing-campaigns.types';
import {getCampaignsInitialLazyData, getDefaultEmailSortingFilter, getDefaultEmailStatusFilters, getAppropriateEmailCampaignsLazyData} from '@Libraries/email-campaigns-library';
import {emailMarketingCampaignsExtraReducers} from '@Components/email-marketing-campaigns/email-marketing-campaigns-extra-reducers';
import {LoadingStates} from '@Utils/loading.util';

const initialState: EmailMarketingCampaignsReduxState = {
  defaultCampaignsLazyData: getCampaignsInitialLazyData(),
  filteredCampaignsLazyData: getCampaignsInitialLazyData(),
  viewType: EmailMarketingCampaignPageType.OWN,
  allCampaigns: {},
  sortingFilter: getDefaultEmailSortingFilter(),
  statusFilter: getDefaultEmailStatusFilters(),
};

const emailMarketingCampaignsSlice = createSlice({
  name: 'emailMarketingCampaignsSlice',
  initialState,
  reducers: {
    onSortingFilterChange: (state, action: PayloadAction<EmailCampaignSortingFilter>): void => {
      state.sortingFilter = action.payload;
      resetFilteredLazyData(state);
    },

    onEmailStatusFilterChange: (state, action: PayloadAction<EmailCampaignStatusFilter>): void => {
      state.statusFilter = action.payload;
      resetFilteredLazyData(state);
    },

    onViewTypeChange: (state, action: PayloadAction<EmailMarketingCampaignPageType>): void => {
      state.viewType = action.payload;
      resetDefaultLazyData(state);
      if (state.viewType === EmailMarketingCampaignPageType.DELETED) {
        doResetFilters(state);
      }
    },

    onLazyLoadScroll: (state): void => {
      const lazyData = getAppropriateEmailCampaignsLazyData(state, state.statusFilter, state.sortingFilter);
      if (lazyData.loadMore) {
        lazyData.loadingState = LoadingStates.NOT_LOADED;
      }
    },

    clearCampaignsGrid: (state): void => {
      state.defaultCampaignsLazyData = {
        ids: [],
        loadMore: false,
        loadingState: LoadingStates.LOADED,
      };
    },

    setIsRenamingForCampaign: (state, action: PayloadAction<ToggleCampaignRenameModePayload>): void => {
      if (state.allCampaigns[action.payload.campaignId]) {
        state.allCampaigns[action.payload.campaignId].isRenaming = action.payload.isRenaming;
      }
    }
  },
  extraReducers: emailMarketingCampaignsExtraReducers,
});

const resetLazyData = (state: EmailMarketingCampaignsReduxState): void => {
  resetDefaultLazyData(state);
  resetFilteredLazyData(state);
};

const resetDefaultLazyData = (state: EmailMarketingCampaignsReduxState): void => {
  state.defaultCampaignsLazyData = getCampaignsInitialLazyData();
};

const resetFilteredLazyData = (state: EmailMarketingCampaignsReduxState): void => {
  state.filteredCampaignsLazyData = getCampaignsInitialLazyData();
};

const doResetFilters = (state: EmailMarketingCampaignsReduxState): void => {
  resetStatusFilter(state);
  resetSortingFilter(state);
};

const resetStatusFilter = (state: EmailMarketingCampaignsReduxState): void => {
  state.statusFilter = getDefaultEmailStatusFilters();
};

const resetSortingFilter = (state: EmailMarketingCampaignsReduxState): void => {
  state.sortingFilter = getDefaultEmailSortingFilter();
};
export const {onLazyLoadScroll, onViewTypeChange, onSortingFilterChange, onEmailStatusFilterChange, clearCampaignsGrid, setIsRenamingForCampaign} = emailMarketingCampaignsSlice.actions;
export const emailMarketingCampaignsReducer = emailMarketingCampaignsSlice.reducer;

import type {
  PaymentStatusTypeFilterValues,
  PurchaseTypeFilterValues,
  RoyaltyFilters} from '@Libraries/my-stuff/designer-dashboard-library';
import {
  getDefaultPaymentStatusFiltersHashmap,
  getDefaultPurchaseFiltersHashmap,
  getInitialLazyDataForTemplateEarnings,
  getInitialLazyLoadedData
} from '@Libraries/my-stuff/designer-dashboard-library';
import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {LoadingStates} from '@Utils/loading.util';
import {designerEarningsExtraReducers} from './designer-earnings-report-extra-reducer';
import type {
  DesignerEarningsReducerState,
  ResetTemplateEarningsReducerPayload,
  SetMessageBannerStatePayload,
  SetRoyaltiesReducerPayload,
  SetTemplateLifetimeEarningsReducerPayload} from './designer-earnings-report.types';
import {
  EarningType,
  LifetimeEarningsTableColumnIds,
  MessageBannerState
} from './designer-earnings-report.types';

const royaltyFiltersInitialState: RoyaltyFilters = {
  purchaseType: getDefaultPurchaseFiltersHashmap(),
  paymentStatusType: getDefaultPaymentStatusFiltersHashmap(),
};

const initialState: DesignerEarningsReducerState = {
  earningsSummaryLoadingState: LoadingStates.NOT_LOADED,
  royaltyFilters: royaltyFiltersInitialState,
  allPayouts: {},
  allRoyalties: {},
  allLifetimeTemplateEarnings: {},
  designerEarnings: undefined,
  payouts: getInitialLazyLoadedData(),
  royalties: getInitialLazyLoadedData(),
  templateLifetimeEarnings: getInitialLazyDataForTemplateEarnings(LifetimeEarningsTableColumnIds.LIFETIME_EARNING),
  filteredRoyalties: getInitialLazyLoadedData(),
  messageBannersState: {
    [EarningType.PAYOUTS]: MessageBannerState.HIDDEN,
    [EarningType.ROYALTIES]: MessageBannerState.VISIBLE,
    [EarningType.TEMPLATE_LIFETIME_EARNINGS]: MessageBannerState.VISIBLE,
  },
};

const designerEarningsSlice = createSlice({
  name: 'designerEarnings',
  initialState,
  reducers: {
    setPurchaseTypeFilterState: (state, action: PayloadAction<PurchaseTypeFilterValues>) => {
      state.royaltyFilters.purchaseType = action.payload;
      resetFiltersLazyState(state);
    },

    setPaymentTypeFilterState: (state, action: PayloadAction<PaymentStatusTypeFilterValues>) => {
      state.royaltyFilters.paymentStatusType = action.payload;
      resetFiltersLazyState(state);
    },

    setRoyalties: (state, action: PayloadAction<SetRoyaltiesReducerPayload>) => {
      state.allRoyalties = action.payload.allRoyalties;
      state.royalties = action.payload.royalties;
    },

    setTemplateLifetimeEarnings: (state, action: PayloadAction<SetTemplateLifetimeEarningsReducerPayload>): void => {
      state.allLifetimeTemplateEarnings = action.payload.allLifetimeTemplateEarnings;
      state.templateLifetimeEarnings = action.payload.templateLifetimeEarnings;
    },

    resetTemplateLifetimeEarnings: (state, action: PayloadAction<ResetTemplateEarningsReducerPayload>): void => {
      state.templateLifetimeEarnings = getInitialLazyDataForTemplateEarnings(action.payload.sortColumnId, action.payload.sortDirection);
    },

    setMessageBannerStateForEarningType: (state, action: PayloadAction<SetMessageBannerStatePayload>): void => {
      state.messageBannersState[action.payload.earningType] = action.payload.state;
    },
  },
  extraReducers: designerEarningsExtraReducers,
});

const resetFiltersLazyState = (state: DesignerEarningsReducerState): void => {
  state.filteredRoyalties = getInitialLazyLoadedData();
};

export const {
  setPurchaseTypeFilterState,
  setPaymentTypeFilterState,
  setRoyalties,
  setMessageBannerStateForEarningType,
  resetTemplateLifetimeEarnings,
  setTemplateLifetimeEarnings,
} = designerEarningsSlice.actions;
export const designerEarningsReducer = designerEarningsSlice.reducer;

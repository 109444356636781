import {createSlice} from '@reduxjs/toolkit';
import {
  BRANDS_LOADING_STATES,
  getBrandFromList,
  getBrandsWithoutTemporaryIds,
  getTemporaryBrandObject,
  getUniqueNameForNewBrand,
  movePrimaryLogoToStart,
} from '@Libraries/brands-library.js';
import {brandsExtraReducers, resetActivityLogForBrand} from './brands-extra-reducers.js';
import {getUniqueString} from '@Utils/string.util.ts';
import {EMPTY_SELECTABLE_COLORS_STATE, SELECTABLE_COLOR_EMPTY} from '@Components/mystuff-brands/brand-kits.types';

const initialState = () => {
  return {
    brands: [],
    activeBrandId: null,
    brandContent: {},
    brandActivityMap: {}, //brandId: {loaded: 1,2,3, ids: []}
    brandActivity: {},
    brandActivityDetails: {}, // JSON data detailing what the user changed inside the activity
    brandsLoadingState: BRANDS_LOADING_STATES.NOT_LOADED,
    isNewBrandBeingCreated: false,
    isUserTeamAdmin: false,
    showNewBrandMobileWizard: false,
    fonts: {},
    colors: {},
    logos: {},
    selectableState: {
      selectedColors: {
        ids: EMPTY_SELECTABLE_COLORS_STATE,
        defaultSelected: false,
      },
      selectedLogo: {
        id: -1,
        defaultSelected: false,
      },
    },
  };
}

const brandsSlice = createSlice({
  name: 'Brands',
  initialState,
  reducers: {
    setBrands: {
      reducer: (state, action) => {
        const {brands, activeBrands} = action.payload;
        state.brands = brands;
        state.activeBrands = activeBrands;
      },

      prepare: (brands, activeBrands) => {
        return {payload: {brands, activeBrands}};
      },
    },

    removeBrand: (state, action) => {
      state.brands = state.brands.filter((brand) => brand.brandId !== action.payload);
    },

    removeAllTemporaryBrands: (state) => {
      state.brands = getBrandsWithoutTemporaryIds(state.brands);
    },
    editBrandProperty: {
      reducer: (state, action) => {
        const {payload} = action,
          {brandId, propertyKey, propertyValue} = payload;
        let brand = getBrandFromList(action.payload.brandId, state.brands);
        brand[propertyKey] = propertyValue;
      },

      prepare: (brandId, propertyKey, propertyValue) => {
        return {payload: {brandId, propertyKey, propertyValue}};
      },
    },

    addBrand: (state, action) => {
      state.brands.push(action.payload);
    },

    addBrandImage: (state, action) => {
      addBrandLogoInfoFromServerInStore(state, action.payload);
    },

    hideNewBrandWizard: (state) => {
      state.showNewBrandMobileWizard = false;
    },
    setBrandsLoadingState: (state, action) => {
      state.brandsLoadingState = action.payload;
    },
    setSelectedColorsInSelectableState: (state, action) => {
      state.selectableState.selectedColors = action.payload;
    },
    setSelectedLogoInSelectableState: (state, action) => {
      state.selectableState.selectedLogo = action.payload;
    },
  },

  extraReducers: brandsExtraReducers,
});

/**
 *
 * @param state
 * @param ajaxResponse
 */
const addBrandLogoInfoFromServerInStore = (state, ajaxResponse) => {
  state.brandContent[ajaxResponse.brandId].logos.ids.push(ajaxResponse.idbrandImage);
  state.logos[ajaxResponse.idbrandImage] = mapUploadResponseToBrandLogoObject(ajaxResponse);
  movePrimaryLogoToStart(state, ajaxResponse.brandId);
  resetActivityLogForBrand(state, ajaxResponse.brandId);
};

/**
 * creates a brandLogo object from a json serialized UserImageVo from the server
 * @param data
 * @returns {Object}
 */
const mapUploadResponseToBrandLogoObject = (data) => {
  return {
    ...data,
    addedBy: data.uploaderId,
  };
};

/**
 * Adds a temporary brand to state and returns the temporary ID
 * @param brands
 * @returns {Object}
 */
export const addTemporaryBrandToState = (brands) => {
  const newName = getUniqueNameForNewBrand(brands),
    newBrandObject = getTemporaryBrandObject(getUniqueString(), newName, PMW.getUserId());
  PMW.redux.store.dispatch(addBrand(newBrandObject));
  return newBrandObject;
};

export const {
  setBrands,
  addBrand,
  editBrandProperty,
  removeBrand,
  removeAllTemporaryBrands,
  setActiveBrandForMyStuff,
  addBrandImage,
  hideNewBrandWizard,
  setBrandsLoadingState,
  setSelectableState,
  setSelectedColorsInSelectableState,
  setSelectedLogoInSelectableState,
} = brandsSlice.actions;

export const brandsReducer = brandsSlice.reducer;

import {SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {hasInvalidCharacters, isValidURL} from '@Utils/url.util';

export const initSocialMediaProfileLinks = (): Record<SocialMediaProfile, string> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: '',
    [SocialMediaProfile.FACEBOOK]: '',
    [SocialMediaProfile.X]: '',
    [SocialMediaProfile.YOUTUBE]: '',
    [SocialMediaProfile.TIKTOK]: '',
    [SocialMediaProfile.PINTEREST]: '',
    [SocialMediaProfile.LINKEDIN]: '',
  };
};

export const initSocialMediaLinksErrorStates = (): Record<SocialMediaProfile, boolean> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: false,
    [SocialMediaProfile.FACEBOOK]: false,
    [SocialMediaProfile.X]: false,
    [SocialMediaProfile.YOUTUBE]: false,
    [SocialMediaProfile.TIKTOK]: false,
    [SocialMediaProfile.PINTEREST]: false,
    [SocialMediaProfile.LINKEDIN]: false,
  };
};

export const initSocialMediaBaseUrls = (): Record<SocialMediaProfile, string> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: 'instagram.com',
    [SocialMediaProfile.FACEBOOK]: 'facebook.com',
    [SocialMediaProfile.X]: 'x.com',
    [SocialMediaProfile.YOUTUBE]: 'youtube.com',
    [SocialMediaProfile.TIKTOK]: 'tiktok.com',
    [SocialMediaProfile.PINTEREST]: 'pinterest.com',
    [SocialMediaProfile.LINKEDIN]: 'linkedin.com',
  };
};

export const initSocialMediaAccountsUsernames = (): Record<SocialMediaProfile, string[]> => {
  return {
    [SocialMediaProfile.INSTAGRAM]: [],
    [SocialMediaProfile.FACEBOOK]: [],
    [SocialMediaProfile.X]: [],
    [SocialMediaProfile.YOUTUBE]: [],
    [SocialMediaProfile.TIKTOK]: [],
    [SocialMediaProfile.PINTEREST]: [],
    [SocialMediaProfile.LINKEDIN]: [],
  };
};

export const isValidSocialMediaUsername = (platform: string, input: string): boolean => {
  const usernameRegex = /^[a-zA-Z0-9._]{1,30}$/;
  return usernameRegex.test(input) && !input.includes(platform);
};

export const formatSocialMediaLinks = (socialMediaLinks: Record<SocialMediaProfile, string>): Record<SocialMediaProfile, string> => {
  const formattedUrls: Record<SocialMediaProfile, string> = initSocialMediaProfileLinks();

  Object.entries(socialMediaLinks).forEach(([platform, socialMediaUrl]) => {
    if (socialMediaUrl.startsWith('http')) {
      formattedUrls[platform as SocialMediaProfile] = socialMediaUrl;
    } else {
      formattedUrls[platform as SocialMediaProfile] = socialMediaUrl !== '' ? `https://${socialMediaUrl}` : '';
    }
  });

  return formattedUrls;
};

export const isValidSocialMediaUrl = (platform: string, url: string): boolean => {
  const urlParts = url.split('/');
  if (!urlParts[urlParts.length - 1] || urlParts[urlParts.length - 1] === '') {
    return false;
  }
  return url.includes(`${platform}.com`) && isValidURL(url) && !hasInvalidCharacters(url);
};

export const getSocialMediaTypeMap = (): Record<string, SocialMediaProfile> => {
  return {
    instagram: SocialMediaProfile.INSTAGRAM,
    'ig-business': SocialMediaProfile.INSTAGRAM,
    facebook: SocialMediaProfile.FACEBOOK,
    fb_profile: SocialMediaProfile.FACEBOOK,
    fb_page: SocialMediaProfile.FACEBOOK,
    fb_group: SocialMediaProfile.FACEBOOK,
    x: SocialMediaProfile.X,
    twitter: SocialMediaProfile.X,
    youtube: SocialMediaProfile.YOUTUBE,
    'youtube-channel': SocialMediaProfile.YOUTUBE,
    tiktok: SocialMediaProfile.TIKTOK,
    'tiktok-profile': SocialMediaProfile.TIKTOK,
    pinterest: SocialMediaProfile.PINTEREST,
    'linkedin-page': SocialMediaProfile.LINKEDIN,
    'linkedin-profile': SocialMediaProfile.LINKEDIN,
  };
};

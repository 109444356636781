import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  DesignerEarningsData,
  DesignerPayout,
  DesignerRoyalty,
  LazyLoadAjaxParams,
  DesignerRoyaltiesAjaxParams,
  DesignerTemplateLifetimeEarning,
  DesignerTemplateEarningsAjaxParams} from '@Libraries/my-stuff/designer-dashboard-library';
import {
  shouldMoreRoyaltiesBeFetched
} from '@Libraries/my-stuff/designer-dashboard-library';
import type {RootState} from '@/store';

export const fetchDesignerEarningsStats = createAsyncThunk<DesignerEarningsData>('fetchDesignerEarningsStats', async () => {
  return window.PMW.readLocal('posters/getDesignerEarningStats');
});

export const fetchDesignerPayouts = createAsyncThunk<DesignerPayout[], LazyLoadAjaxParams>('fetchDesignerPayouts', async ({limit, offsetId}) => {
  return window.PMW.readLocal('designers/getSuccessfullPayoutsToDesigner', {limit, offset: offsetId ?? null});
});

export const fetchDesignerRoyalties = createAsyncThunk<DesignerRoyalty[], DesignerRoyaltiesAjaxParams>(
  'fetchDesignerRoyalties',
  async ({limit, offsetId, getRefundedRoyalties, paymentStatuses}) => {
    return window.PMW.readLocal('designers/getRoyaltiesForDesigner', {limit, offset: offsetId, paymentStatuses, getRefundedRoyalties});
  },
  {
    condition: (_, {getState}) => {
      const {designerEarnings} = getState() as RootState;
      return shouldMoreRoyaltiesBeFetched(designerEarnings.royaltyFilters, designerEarnings.royalties, designerEarnings.filteredRoyalties);
    },
  }
);

export const fetchTemplateLifeTimeEarnings = createAsyncThunk<DesignerTemplateLifetimeEarning[], DesignerTemplateEarningsAjaxParams>(
  'fetchTemplateLifetimeEarnings',
  async (params: DesignerTemplateEarningsAjaxParams): Promise<DesignerTemplateLifetimeEarning[]> => {
    return window.PMW.readLocal('designers/getLifetimeEarningsForDesignerTemplates', params);
  }
);

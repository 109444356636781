import React from "react";
import PropTypes from 'prop-types';
import './popup-toggle-button.scss';


/**
 * A re-usable icon-button for toggling the popup. It is set as the default button for the PopupMenu component with the three dots icon as the default setting.
 * However, this component can be imported, re-used as per needs. See PropTypes for prop details
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @return {JSX.Element}
 */
const PopupToggleButton = ({className, colorClass, ariaLabel, icon, onClick, disabled}) => {

    return (
        <button className={`popup-toggle-button hover-transition-all radius-2 flex-center ${colorClass} ${className}`} aria-label={ariaLabel} onClick={onClick} disabled={disabled} >
            <i className={`popup-toggle-button-icon ${icon}`} aria-hidden={true} />
        </button>
    );
}

PopupToggleButton.propTypes = {
    /**
     * the icon-* class for the icon. Default: icon-menu-dots
     */
    icon: PropTypes.string,
    /**
     * any class to give to the button
     */
    className: PropTypes.string,
    /**
     * the color class. Default: neutral-70 i.e. content-body
     */
    colorClass: PropTypes.string,
    /**
     * an aria-label to give to the component since it is an icon button. Default: menu button
     */
    ariaLabel: PropTypes.string,
    /**
     * the click handler
     */
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}

PopupToggleButton.defaultProps = {
    icon: 'icon-menu-dots',
    className: '',
    colorClass: 'content-body',
    ariaLabel: i18next.t('pmwjs_open_menu'),
    onClick: $.noop,
    disabled: false
}


export default PopupToggleButton;


import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  BusinessProfileResponse,
  BusinessProfileData,
  BusinessProfileAjaxResponse,
  BusinessProfileDeleteData,
} from '@Components/business-profiles-wizard/business-profiles-wizard.types';

export const getBusinessProfile = createAsyncThunk<BusinessProfileResponse>('getBusinessProfile', async () => {
  return window.PMW.readLocal('mybusiness/businessProfileWizard');
});

export const createBusinessProfile = createAsyncThunk<BusinessProfileAjaxResponse, BusinessProfileData>('createBusinessProfile', async (data: BusinessProfileData) => {
  return window.PMW.writeLocal('mybusiness/createBusinessProfile', data);
});

export const updateBusinessProfile = createAsyncThunk<BusinessProfileAjaxResponse, BusinessProfileData>('updateBusinessProfile', async (data: BusinessProfileData) => {
  return window.PMW.writeLocal('mybusiness/updateBusinessProfile', data);
});

export const deleteBusinessProfile = createAsyncThunk<BusinessProfileAjaxResponse, BusinessProfileDeleteData>('deleteBusinessProfile', async (data: BusinessProfileDeleteData) => {
  return window.PMW.writeLocal('mybusiness/deleteBusinessProfile', data);
});

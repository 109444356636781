import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {remove} from '@Utils/array.util';
import type {IdUserHasCustomField} from '@Panels/add-mailing-list-panel/add-mailing-list-panel.types';
import type {
  AddEmailsToMailingListParams,
  AddMailingListPanelData,
  AudiencesMap,
  MailingList,
  MailingListsMap,
  SearchTermPayload,
  UpdateFiltersParams,
} from '@Components/mailing-list-wizard/mailing-list-wizard.types';
import type {Audience, UserCustomFieldMetaMap, UserCustomFieldMeta} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience.types';
import type {CreateSignUpFormData, UserSelectedMailingListNamesMap, UserSignupFormMailingListData} from '@Panels/create-signup-form-panel/create-signup-form-panel.types';
import {emailMarketingCampaignAudienceExtraReducers} from './email-marketing-campaign-audience-extra-reducers';

export interface AudienceState {
  mailingLists: MailingListsMap;
  audiences: AudiencesMap;
  isMailingListRenderComplete: boolean;
  isSignUpFormRenderComplete: boolean;
  mailingListCount: number;
  signUpFormCount: number;
  isMailingListSignUpFormLoading: boolean;
  userCustomFieldsMetaMap: UserCustomFieldMetaMap | null;
  addMailingListPanel: AddMailingListPanelData;
  createSignUpFormPanel: CreateSignUpFormData;
}

const initialState: AudienceState = {
  mailingLists: {},
  audiences: {},
  isMailingListRenderComplete: false,
  isSignUpFormRenderComplete: false,
  mailingListCount: 0,
  signUpFormCount: 0,
  isMailingListSignUpFormLoading: true,
  userCustomFieldsMetaMap: null,
  addMailingListPanel: {
    panelErrorMessage: '',
    panelInfoMessage: '',
    panelNextButtonTooltipText: '',
    audienceMapCardDataMap: {},
  },
  createSignUpFormPanel: {},
};

export interface MailingListSignUpFormCountsPayload {
  mailingListCount: number;
  signUpFormCount: number;
}

export interface UserCustomFieldMetaPayload {
  userCustomFieldId: IdUserHasCustomField;
  userCustomFieldMeta: UserCustomFieldMeta;
}

export interface UserCustomFieldValueUpdatePayload {
  userCustomFieldId: IdUserHasCustomField;
  audienceId: number;
  value: string;
}

export interface AudienceMapCardDataPayload {
  cardId: string;
  cardError?: string;
  isLoading?: boolean;
}

export interface AudienceMapDataPayload {
  idAudience: number;
  audienceData: Audience;
}

const emailCampaignAudienceSlice = createSlice({
  name: 'emailCampaignAudience',
  initialState,
  reducers: {
    addAudiencesToMailingList: (state, {payload}: PayloadAction<AddEmailsToMailingListParams>) => {
      const emailIds = [] as Array<number>;

      payload.audiences.forEach((audience: Audience) => {
        emailIds.push(audience.id);
        state.audiences[audience.id] = audience;
        state.audiences[audience.id].customFields = audience.customFields;
      });

      state.mailingLists[payload.idMailingList].emailIds = [...state.mailingLists[payload.idMailingList].emailIds, ...emailIds];

      if (payload.totalEmailsCount) {
        state.mailingLists[payload.idMailingList].totalEmailsCount = payload.totalEmailsCount;
      }
    },
    updateFilters: (state, {payload}: PayloadAction<UpdateFiltersParams>) => {
      state.mailingLists[payload.mailingListId].filters = {...state.mailingLists[payload.mailingListId].filters, ...payload.states};
    },
    updateSearchTerm: (state, {payload}: PayloadAction<SearchTermPayload>) => {
      state.mailingLists[payload.mailingListId].searchTerm = payload.searchTerm;
    },
    updateIsMailingListRenderComplete: (state, {payload}: PayloadAction<boolean>) => {
      state.isMailingListRenderComplete = payload;
    },
    updateIsSignUpFormRenderComplete: (state, {payload}: PayloadAction<boolean>): void => {
      state.isSignUpFormRenderComplete = payload;
    },
    updateMailingListsSignUpFormsCount: (state, {payload}: PayloadAction<MailingListSignUpFormCountsPayload>): void => {
      state.mailingListCount = payload.mailingListCount;
      state.signUpFormCount = payload.signUpFormCount;
      state.isMailingListSignUpFormLoading = false;
    },
    addUserCustomFieldMeta: (state, {payload}: PayloadAction<UserCustomFieldMetaPayload>): void => {
      if (state.userCustomFieldsMetaMap === null) {
        state.userCustomFieldsMetaMap = {};
      }

      if (Object.keys(state.userCustomFieldsMetaMap).includes(String(payload.userCustomFieldId))) {
        return;
      }

      state.userCustomFieldsMetaMap[payload.userCustomFieldId] = payload.userCustomFieldMeta;
    },
    updateUserCustomFieldMeta: (state, {payload}: PayloadAction<UserCustomFieldMetaPayload>): void => {
      if (!state.userCustomFieldsMetaMap) {
        return;
      }

      if (!Object.keys(state.userCustomFieldsMetaMap).includes(String(payload.userCustomFieldId))) {
        throw new Error('User custom field id does not exist');
      }
      state.userCustomFieldsMetaMap[payload.userCustomFieldId] = payload.userCustomFieldMeta;
    },
    updateUserCustomFieldValue: (state, {payload: {audienceId, userCustomFieldId, value}}: PayloadAction<UserCustomFieldValueUpdatePayload>): void => {
      if (state.userCustomFieldsMetaMap === null) {
        return;
      }

      if (!Object.keys(state.userCustomFieldsMetaMap).includes(String(userCustomFieldId))) {
        return;
      }

      if (!state.audiences[audienceId].customFields) {
        return;
      }

      state.audiences[audienceId].customFields[userCustomFieldId] = value;
    },
    upsertAudienceMapCardData: (state, {payload}: PayloadAction<AudienceMapCardDataPayload>): void => {
      doUpsertAudienceMapCardData(state, payload);
    },
    updateAddMailingListPanelData: (state, {payload}: PayloadAction<AddMailingListPanelData>): void => {
      doUpdateAddMailingListPanelData(state, payload);
    },
    updateAddMailingListPanelErrorMessage: (state, {payload}: PayloadAction<string>): void => {
      state.addMailingListPanel.panelErrorMessage = payload;
    },
    updateAddMailingListPanelInfoMessage: (state, {payload}: PayloadAction<string>): void => {
      state.addMailingListPanel.panelInfoMessage = payload;
    },
    updateAudienceDataForMailingListAudience: (state, {payload}: PayloadAction<AudienceMapDataPayload>): void => {
      doUpdateAudienceDataForMailingListAudience(state, payload);
    },
    updateAddMailingListPanelNextButtonToolTip: (state, {payload}: PayloadAction<string>): void => {
      doUpdateAddMailingListPanelNextButtonToolTip(state, payload);
    },
    updateCreateSignUpFormPanelData: (state, {payload}: PayloadAction<CreateSignUpFormData>): void => {
      state.createSignUpFormPanel = payload;
    },
    updateCreateSignUpFormPanelLists: (state, {payload}: PayloadAction<UserSignupFormMailingListData[]>): void => {
      doUpdateCreateSignUpFormPanelLists(state, payload);
    },
    updateCreateSignUpFormPanelSelectedLists: (state, {payload}: PayloadAction<UserSelectedMailingListNamesMap>): void => {
      state.createSignUpFormPanel.selectedMailingLists = payload;
    },
  },
  extraReducers: emailMarketingCampaignAudienceExtraReducers,
});

export const addMailingList = (state: AudienceState, idMailingList: number, data: MailingList): void => {
  state.mailingLists[idMailingList] = data;
};

export const addAudienceIdsToMailingList = (state: AudienceState, idMailingList: number, emailIds: Array<number>): void => {
  state.mailingLists[idMailingList].emailIds = [...state.mailingLists[idMailingList].emailIds, ...emailIds];
};

export const addAudience = (state: AudienceState, audience: Audience): void => {
  state.audiences[audience.id] = audience;
  state.audiences[audience.id].customFields = audience.customFields;
};

export const changeMailingListName = (state: AudienceState, idMailingList: number, newName: string): void => {
  state.mailingLists[idMailingList].name = newName;
};

export const removeMailingList = (state: AudienceState, idMailingList: number): void => {
  if (state.mailingLists[idMailingList]) {
    state.mailingLists[idMailingList].emailIds.map((idEmail: number) => {
      return delete state.audiences[idEmail];
    });
    delete state.mailingLists[idMailingList];
  }
};

export const removeAudience = (state: AudienceState, idMailingList: number, idEmail: number, index: number): void => {
  state.mailingLists[idMailingList].emailIds = remove(state.mailingLists[idMailingList].emailIds, index);
  delete state.audiences[idEmail];
  state.mailingLists[idMailingList].totalEmailsCount -= 1;
};

export const doUpsertAudienceMapCardData = (state: AudienceState, payload: AudienceMapCardDataPayload): void => {
  let {audienceMapCardDataMap} = state.addMailingListPanel;
  if (!audienceMapCardDataMap) {
    audienceMapCardDataMap = {};
  }

  const existingState = audienceMapCardDataMap[payload.cardId];
  state.addMailingListPanel.audienceMapCardDataMap = {
    ...audienceMapCardDataMap,
    [payload.cardId]: {
      cardError: Object.keys(payload).includes('cardError') ? payload.cardError : existingState?.cardError || '',
      isLoading: Object.keys(payload).includes('isLoading') ? payload.isLoading : existingState?.isLoading || false,
    },
  };
};
export const doUpdateAddMailingListPanelData = (state: AudienceState, payload: AddMailingListPanelData): void => {
  state.addMailingListPanel = {...payload};
};

export const doUpdateAudienceDataForMailingListAudience = (state: AudienceState, payload: AudienceMapDataPayload): void => {
  if (!state.audiences) {
    state.audiences = {[payload.idAudience]: payload.audienceData};
    return;
  }
  if (Object.keys(state.audiences).includes(String(payload.idAudience))) {
    state.audiences[payload.idAudience] = {...state.audiences[payload.idAudience], ...payload.audienceData};
    return;
  }

  state.audiences[payload.idAudience] = {...payload.audienceData};
};

export const doUpdateAddMailingListPanelNextButtonToolTip = (state: AudienceState, payload: string): void => {
  state.addMailingListPanel.panelNextButtonTooltipText = payload;
};

export const doUpdateCreateSignUpFormPanelLists = (state: AudienceState, payload: UserSignupFormMailingListData[]): void => {
  state.createSignUpFormPanel.userMailingLists = payload;
};

export const {
  addAudiencesToMailingList,
  updateFilters,
  updateSearchTerm,
  updateIsMailingListRenderComplete,
  updateIsSignUpFormRenderComplete,
  updateMailingListsSignUpFormsCount,
  addUserCustomFieldMeta,
  updateUserCustomFieldMeta,
  upsertAudienceMapCardData,
  updateAddMailingListPanelData,
  updateAddMailingListPanelErrorMessage,
  updateAddMailingListPanelInfoMessage,
  updateUserCustomFieldValue,
  updateAddMailingListPanelNextButtonToolTip,
  updateCreateSignUpFormPanelLists,
  updateCreateSignUpFormPanelSelectedLists,
} = emailCampaignAudienceSlice.actions;
export const emailMarketingCampaignAudienceReducer = emailCampaignAudienceSlice.reducer;

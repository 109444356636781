import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {MyStuffReduxState} from '@Components/mystuff-content/my-stuff.types';

const initialState: MyStuffReduxState = {
  isMyStuffLoading: false,
  showWarningOnPageChange: false,
  pageWarningText: '',
  isMyStuffFooterOpen: false
};

const myStuffSlice = createSlice({
  name: 'myStuff',
  initialState,
  reducers: {
    showMyStuffLoadingIndicator: (state) => {
      state.isMyStuffLoading = true;
    },
    hideMyStuffLoadingIndicator: (state) => {
      state.isMyStuffLoading = false;
    },
    setWarningTextForPageChange: (state, action: PayloadAction<string>): void => {
      state.showWarningOnPageChange = true;
      state.pageWarningText = action.payload;
    },

    hidePageChangeWarning: (state): void => {
      state.showWarningOnPageChange = false;
      state.pageWarningText = '';
    },

    setShowWarningOnPageChange: (state, action: PayloadAction<boolean>): void => {
      state.showWarningOnPageChange = action.payload;
    },

    setIsMyStuffFooterOpen: (state, action: PayloadAction<boolean>): void => {
      state.isMyStuffFooterOpen = action.payload;
    }
  },
});

export const {showMyStuffLoadingIndicator, hideMyStuffLoadingIndicator, hidePageChangeWarning, setWarningTextForPageChange, setShowWarningOnPageChange, setIsMyStuffFooterOpen} = myStuffSlice.actions;
export const myStuffReducer = myStuffSlice.reducer;

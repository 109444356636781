export enum LoadingStates {
  NOT_LOADED,
  LOADING,
  LOADED,
}

export const fetchResourcesIfNotLoaded = async (currentState: LoadingStates, fetchFunction: () => Promise<any>): Promise<void> => {
  if (currentState === LoadingStates.NOT_LOADED) {
    await fetchFunction();
  }
};

export const isLoaded = (loadingState: LoadingStates): boolean => {
  return loadingState === LoadingStates.LOADED;
};

export const isLoading = (loadingState: LoadingStates): boolean => {
  return loadingState === LoadingStates.LOADING;
};

export const isLoadingOrNeedsLoading = (loadingState: LoadingStates): boolean => {
  return loadingState === LoadingStates.NOT_LOADED || loadingState === LoadingStates.LOADING;
}

export const isLoadingOrLoaded = (loadingState: LoadingStates): boolean => {
  return loadingState === LoadingStates.LOADING || loadingState === LoadingStates.LOADED;
}

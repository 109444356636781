export enum AddMailingListStep {
  CHOOSE_UPLOAD_METHOD,
  UPLOAD_MANUALLY,
  CONFIRM_EMPTY_MANUAL_INPUT,
  UPLOAD_CSV,
  MAP_AUDIENCE_FIELDS,
  ADD_AUDIENCE_SUCCESS,
}

export const MAX_AUDIENCE_CUSTOM_FIELDS_COUNT = 20;
export const MAX_AUDIENCE_CUSTOM_FIELD_LABEL_LENGTH = 20;

export const CUSTOM_FIELD_KEY_PREFIX = `user-custom-field-`;

export enum AudienceDefaultField {
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  ADDRESS = 'ADDRESS',
  ADDRESS_COUNTRY = 'ADDRESS_COUNTRY',
  ADDRESS_STATE = 'ADDRESS_STATE',
  ADDRESS_CITY = 'ADDRESS_CITY',
  ADDRESS_STREET = 'ADDRESS_STREET',
  BIRTHDAY = 'BIRTHDAY',
  PHONE_NUMBER = 'PHONE_NUMBER',
  SKIP_THIS_FIELD = 'SKIP_THIS_FIELD',
  NOT_SELECTED = 'NOT_SELECTED',
}

export type IdUserHasCustomField = number;
export type AudienceDataField = AudienceDefaultField | IdUserHasCustomField;
export type FieldMappedData = Record<string, (string | null)[]>;

export interface AudienceFieldListItem {
  field: Omit<AudienceDataField, AudienceDefaultField.NOT_SELECTED | AudienceDefaultField.ADDRESS>;
  icon: string;
}

export const DEFAULT_AUDIENCE_FIELDS_WITH_ICONS: AudienceFieldListItem[] = [
  {
    field: AudienceDefaultField.EMAIL,
    icon: 'icon-envelope',
  },
  {
    field: AudienceDefaultField.FIRST_NAME,
    icon: 'icon-user',
  },
  {
    field: AudienceDefaultField.LAST_NAME,
    icon: 'icon-user',
  },
  {
    field: AudienceDefaultField.PHONE_NUMBER,
    icon: 'icon-phone',
  },
  {
    field: AudienceDefaultField.BIRTHDAY,
    icon: 'icon-calendar',
  },
  {
    field: AudienceDefaultField.ADDRESS_STREET,
    icon: 'icon-location',
  },
  {
    field: AudienceDefaultField.ADDRESS_CITY,
    icon: 'icon-location',
  },
  {
    field: AudienceDefaultField.ADDRESS_STATE,
    icon: 'icon-location',
  },
  {
    field: AudienceDefaultField.ADDRESS_COUNTRY,
    icon: 'icon-location',
  },
  {
    field: AudienceDefaultField.SKIP_THIS_FIELD,
    icon: 'icon-ban',
  },
];

export enum AddMailingListPanelProgressState {
  'DEFAULT',
  'DISABLED',
  'LOADING',
}

export enum AddMailingListPanelUploadMethod {
  UPLOAD_CSV = 'Upload CSV',
  MANUAL_INPUT = 'Manual Input',
}

import {createAsyncThunk} from "@reduxjs/toolkit";

export const initEmailMarketingCampaignSettings = createAsyncThunk(
    'initEmailMarketingCampaignSettings',
    async () => {
        return await PMW.readLocal('emailmarketing/initEmailMarketingCampaignSettings');
    }
);

export const deleteSenderProfile = createAsyncThunk(
    'deleteSenderProfile',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/deleteSenderProfile', data);
    }
);

export const updateSenderProfile = createAsyncThunk(
    'updateSenderProfile',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/updateSenderProfile', data);
    }
);

export const createSenderProfile = createAsyncThunk(
    'createSenderProfile',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/createSenderProfile', data);
    }
);

export const addPhysicalAddress = createAsyncThunk(
    'addPhysicalAddress',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/addPhysicalAddress', data);
    }
);

export const updatePhysicalAddress = createAsyncThunk(
    'updatePhysicalAddress',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/updatePhysicalAddress', data);
    }
);

export const updateDefaultSenderProfile = createAsyncThunk(
    'updateDefaultSenderProfile',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/updateDefaultSenderProfile', data);
    }
);

export const resendSenderProfileVerificationEmail = createAsyncThunk(
    'resendSenderProfileVerificationEmail',
    async (data) => {
        return await PMW.writeLocal('emailmarketing/resendVerificationEmail', data);
    }
);

export const isEmailVerifiedForSenderProfile = createAsyncThunk(
    'isEmailVerifiedForSenderProfile',
    async (data) => {
        return await PMW.readLocal('emailmarketing/isEmailVerifiedForSenderProfile', data);
    }
);

export const checkEmailVerificationForSenderProfile = createAsyncThunk(
    'checkEmailVerificationForSenderProfile',
    async (data) => {
        return await PMW.readLocal('emailmarketing/checkEmailVerificationForSenderProfile', data);
    }
);
import {DAYS_IN_WEEK, getDateFromUnixTimestamp, getDayMonthYearFormat, getFirstDayOfMonth, getPreviousSunday, getUnixTimestamp} from '@Utils/date.util';
import type {
  AreaFilterInfo,
  BaseEvent,
  ContentPlannerEntries,
  ContentPlannerFilters,
  ContentPlannerLazyLoadingMap,
  EntryTypeFilter,
  EventEntry,
  PlannerSeenServerResponse,
  ScheduledEntry,
  SocialGlobalEventFilter,
  UserCalendarPreferences,
} from '@Components/content-planner/content-planner.types';
import {EntryTypeFilterOption, EventType, PlannerSeenCacheKeyPrefix, SocialGlobalEventFilterOption} from '@Components/content-planner/content-planner.types';
import type {FiltersHashmap} from '@Components/checkbox-filters/checkbox-filters.types';
import {isNumber} from 'lodash';

export const DEFAULT_NUM_ROWS = 6;
export const DEFAULT_EVENT_ID = -1;
export const MAX_EVENT_DESCRIPTION_LENGTH = 255;

export enum EntryType {
  EMAIL = 'email',
  SOCIAL = 'social_post',
  EVENT = 'event',
}

export interface Dictionary<T> {
  [index: string]: T;
}

export const getEntriesMapKey = (date: Date): string => {
  const dateFrom = getUnixTimestamp(getCalendarStartDate(date));
  const dateTo = getUnixTimestamp(getCalendarEndDate(DEFAULT_NUM_ROWS, date));

  return dateFrom.toString() + dateTo.toString();
};

export const shouldScheduledEntryBeShown = (entry: ScheduledEntry, entryTypeFilters: EntryTypeFilter): boolean => {
  return (
    (entry.entryType === EntryType.EMAIL && entryTypeFilters[EntryTypeFilterOption.EMAIL]) ||
    (entry.entryType === EntryType.SOCIAL && entryTypeFilters[EntryTypeFilterOption.SOCIAL])
  );
};

export const shouldEventEntryBeShown = (event: EventEntry, filters: ContentPlannerFilters): boolean => {
  return (isInternalEvent(event) && shouldInternalEventBeShown(event, filters)) || (isCustomEvent(event) && filters.entryTypeFilters[EntryTypeFilterOption.CUSTOM_EVENT]);
};

export const shouldInternalEventBeShown = (event: EventEntry, filters: ContentPlannerFilters): boolean => {
  const shouldShowSocialEvent = event.type === EventType.SOCIAL_MEDIA && filters.socialGlobalEventFilters[SocialGlobalEventFilterOption.SOCIAL_MEDIA];
  const shouldShowGlobalEvent = event.type === EventType.GLOBAL && filters.socialGlobalEventFilters[SocialGlobalEventFilterOption.GLOBAL];

  if (shouldShowGlobalEvent || shouldShowSocialEvent) {
    return true;
  }

  const doesEventIncludeRegionFromFilter = getSelectedFilterKeys(filters.eventRegionFilters).some((regionId) => {
    return event.regions.includes(regionId);
  });

  if (doesEventIncludeRegionFromFilter) {
    return true;
  }

  return getSelectedFilterKeys(filters.eventReligionFilters).some((religionId) => {
    return event.religions.includes(religionId);
  });
};

export const getCalendarStartDate = (date = new Date()): Date => {
  return getPreviousSunday(getFirstDayOfMonth(date));
};

export const getCalendarEndDate = (rows: number, date = new Date()): Date => {
  const endDate = getCalendarStartDate(date);
  endDate.setDate(endDate.getDate() + rows * DAYS_IN_WEEK - 1);
  endDate.setHours(23, 59, 59, 0);
  return endDate;
};

export const isDateInCalendar = (calendarMonthDate: Date, targetDate: Date): boolean => {
  return targetDate >= getCalendarStartDate(calendarMonthDate) && targetDate <= getCalendarEndDate(DEFAULT_NUM_ROWS, calendarMonthDate);
};

export const openUpsellingDialogForContentPlanner = (): void => {
  window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_PLUS_ONLY_FEATURE_NAME_CONTENT_PLANNER);
};

export const openIntroDialogForContentPlanner = (): void => {
  window.PMW.showPremiumIntroFeatureDialog(window.PMW.INTRO_FEATURE_NAME_CONTENT_PLANNER);
};

export const openEventsIntroPopupForContentPlanner = (): void => {
  loadContentPlannerDependencies(() => {
    window.PMW.contentPlanner?.openEventsIntroPopup();
  });
};

export const loadContentPlannerDependencies = (onLoad: () => void): void => {
  window.PMW.util.require(typeof window.PMW.contentPlanner === 'undefined', 'contentPlannerDependencies', true, () => {
    onLoad();
  });
};

export const getUserCalendarPreferencesFromState = (filterState: ContentPlannerFilters): UserCalendarPreferences => {
  const {socialGlobalEventFilters, eventRegionFilters, eventReligionFilters} = filterState;

  return {
    showGlobalEvents: socialGlobalEventFilters[SocialGlobalEventFilterOption.GLOBAL],
    showSocialEvents: socialGlobalEventFilters[SocialGlobalEventFilterOption.SOCIAL_MEDIA],
    selectedRegions: getSelectedFilterKeys(eventRegionFilters),
    selectedReligions: getSelectedFilterKeys(eventReligionFilters),
  };
};

export const getIdsFromAreaFiltersList = (areaFilters: AreaFilterInfo[]): string[] => {
  return areaFilters.map((areaFilter) => {
    return areaFilter.id.toString();
  });
};

export const getSelectedFilterKeys = (filter: FiltersHashmap): string[] => {
  return Object.keys(filter).filter((key) => {
    return filter[key];
  });
};

export const hasUserSeenPlanner = async (plannerSeenCacheKeyPrefix: PlannerSeenCacheKeyPrefix): Promise<boolean> => {
  if (!window.PMW.isUserLoggedIn()) {
    return true;
  }

  if (getContentPlannerSeenCache(plannerSeenCacheKeyPrefix)) {
    return true;
  }
  setContentPlannerSeenCache(plannerSeenCacheKeyPrefix);

  const uri = plannerSeenCacheKeyPrefix === PlannerSeenCacheKeyPrefix.EVENTS ? 'hasUserSeenPlannerEvents' : 'hasUserSeenPlanner';
  const data = (await window.PMW.readLocal(`planner/${uri}`)) as PlannerSeenServerResponse;
  data.hasSeen = !isNumber(data.hasSeen) ? parseInt(data.hasSeen, 10) : data.hasSeen;
  return data.hasSeen === 1;
};

export const hasUserSeenPlannerIntro = async (): Promise<boolean> => {
  return hasUserSeenPlanner(PlannerSeenCacheKeyPrefix.INTRO);
};

export const hasUserSeenPlannerEvents = async (): Promise<boolean> => {
  return hasUserSeenPlanner(PlannerSeenCacheKeyPrefix.EVENTS);
};

export const showEventsIntroPopupIfNeeded = (): void => {
  hasUserSeenPlannerEvents().then(
    (hasSeen) => {
      if (!hasSeen) {
        openEventsIntroPopupForContentPlanner();
      }
    },
    (rejectReason) => {
      console.error(rejectReason);
    }
  );
};

const getContentPlannerSeenCache = (cacheKeyPrefix: string): boolean => {
  return window.PMW.getCacheItem(getContentPlannerSeenCacheKey(cacheKeyPrefix)) !== null;
};

const setContentPlannerSeenCache = (cacheKeyPrefix: string): void => {
  window.PMW.setCacheItem(getContentPlannerSeenCacheKey(cacheKeyPrefix), 1);
};

const getContentPlannerSeenCacheKey = (cacheKeyPrefix: string): string => {
  return cacheKeyPrefix + (window.PMW.getUserId() as string);
};

export const isCustomEvent = (event: EventEntry): boolean => {
  return event.type === EventType.CUSTOM;
};

export const isInternalEvent = (event: EventEntry): boolean => {
  return event.type !== EventType.CUSTOM;
};

export const isNonLunarEvent = (event: EventEntry): boolean => {
  return isInternalEvent(event) && event.isLunar !== undefined && !event.isLunar;
};

export const getEmptyEntriesObject = (): ContentPlannerEntries => {
  return {
    scheduledEntries: [],
    eventEntries: [],
  };
};

export const getEntryTypeFiltersInitialState = (): EntryTypeFilter => {
  return {
    [EntryTypeFilterOption.EMAIL]: true,
    [EntryTypeFilterOption.SOCIAL]: true,
    [EntryTypeFilterOption.CUSTOM_EVENT]: true,
  };
};

export const getEventTypeFiltersInitialState = (): SocialGlobalEventFilter => {
  return {
    [SocialGlobalEventFilterOption.GLOBAL]: true,
    [SocialGlobalEventFilterOption.SOCIAL_MEDIA]: true,
  };
};

export const filterScheduledEntriesForDate = (scheduledEntries: ScheduledEntry[], entryTypeFilters: EntryTypeFilter): ScheduledEntry[] => {
  return scheduledEntries.filter((scheduledEntry) => {
    return shouldScheduledEntryBeShown(scheduledEntry, entryTypeFilters);
  });
};

export const filterEventEntriesForDate = (eventEntries: EventEntry[], filters: ContentPlannerFilters): EventEntry[] => {
  return eventEntries.filter((eventEntry) => {
    return shouldEventEntryBeShown(eventEntry, filters);
  });
};

export const getSelectedFilterNames = (filters: FiltersHashmap, filtersSource: AreaFilterInfo[]): string[] => {
  const selectedNames: string[] = [];
  filtersSource.forEach((source) => {
    if (filters[source.id]) {
      selectedNames.push(source.name);
    }
  });

  return selectedNames;
};

export const getFormattedDateForEntryType = (date: Date): string => {
  return getDayMonthYearFormat(date);
};

export const getFormattedDateForEntryTypeFromTimestamp = (timestamp: number): string => {
  return getFormattedDateForEntryType(getDateFromUnixTimestamp(timestamp));
};

export const getEmptyBaseEventEntry = (date = new Date()): BaseEvent => {
  return {
    id: DEFAULT_EVENT_ID,
    timestamp: getUnixTimestamp(date),
    title: '',
    description: '',
  };
};

export const areMoreLazyLoadingEntriesNeeded = (entryTypeLazyLoadData: ContentPlannerLazyLoadingMap): boolean => {
  let entriesNeeded = false;
  Object.keys(entryTypeLazyLoadData).forEach((key: string) => {
    const entryDataForKey = entryTypeLazyLoadData[key];
    if (entryDataForKey.areMoreEntriesNeeded) {
      entriesNeeded = true;
    }
  });
  return entriesNeeded;
};

import type {ReactElement} from 'react';
import React from 'react';
import type {PanelFooterSecondaryCustomActionProps, PanelFooterSecondaryDefaultActionProps} from '@Components/panel';
import styles from './panel-footer-secondary-actions.module.scss';

export type PanelFooterSecondaryActionType = ReactElement<PanelFooterSecondaryDefaultActionProps> | ReactElement<PanelFooterSecondaryCustomActionProps>;

interface PanelFooterSecondaryActionsProps {
  actions?: Array<PanelFooterSecondaryActionType>;
  className?: string;
  fullWidth?: boolean;
}

export function PanelFooterSecondaryActions({actions = [], className = '', fullWidth = false}: PanelFooterSecondaryActionsProps) {
  return actions.length ? <div className={`${className} ${fullWidth ? styles.fullWidth : ''}`}>{actions}</div> : <div />;
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import type {GridStorageTypes, StateProps} from '@Components/base-grid/base-grid-reducer';
import {deleteGridItems, updateGridItem} from '@Components/base-grid/base-grid-reducer';
import {castIdToStringForBaseGrid, findGridItem, GRID_ID, populateAudienceCustomFieldsFromAudienceResponse} from '@Libraries/email-marketing-campaigns-audience-library';
import type {GridEmailCampaignAudienceItemEmailsData} from '@Components/base-grid/components/grid-email-campaign-audience-item';
import type {CreateSignupFormMailingListsResponse} from '@Panels/create-signup-form-panel/create-signup-form-panel.types';
import type {RawCustomFieldValue} from '@Components/mailing-list-wizard/mailing-list-wizard.types';
import type {Audience} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience.types';

export interface GetEmailsByMailingListIdParams {
  idMailingList: number;
  limit: number;
  offset: number;
  doFetchEmailsCount: boolean;
  deletedFilter: number;
}

interface GetEmailsForCSVParams {
  idMailingList: number;
}

interface MailingListViewedParams {
  idMailingList: number;
}

export interface DeleteAudienceParams {
  idMailingList: number;
  audience: GridEmailCampaignAudienceItemEmailsData;
  index: number;
}

export interface UpdateMailingListNameParams {
  idMailingList: number;
  newName: string;
  currentName: string;
}

export interface UpdatePhoneNumberForAudienceInMailingListParams {
  idAudience: number;
  phoneNumber: string;
}

export interface UpdateFirstNameForAudienceInMailingListParams {
  idAudience: number;
  firstName: string;
}

export interface UpdateInfoForAudienceInMailingListParams {
  idAudience: number;
  fullName?: string;
  phoneNumber?: string;
}

interface UpdateContactFieldForAudienceInMailingListParams {
  idAudience: number;
  field: string; // The name of the field (e.g., 'fullName', 'phoneNumber')
  value: string;
}

interface UpdateMailingListNameInGridParams {
  itemId: string;
  newName: string;
}

interface DuplicateMailingListParams {
  idMailingList: string;
}

export interface DuplicateMailingListResponse {
  idMailingList: string;
}

export interface GetEmailsByMailingListIdResponse {
  idMailingList: number;
  name: string;
  audiences: Audience[];
  count: number;
  isSpam: boolean;
  lastViewed: number;
}

interface GetEmailsByMailingListIdAjaxResponse extends Omit<GetEmailsByMailingListIdResponse, 'audiences'> {
  audiences: ResponseAudience[];
}

export interface ResponseAudience extends Omit<Audience, 'customFields'> {
  customFields?: RawCustomFieldValue[];
}

export const getEmailsByMailingListId = createAsyncThunk('getEmailsByMailingListId', async (data: GetEmailsByMailingListIdParams): Promise<GetEmailsByMailingListIdResponse> => {
  const response = (await window.PMW.readLocal('emailmarketing/getEmailsForAudience', data)) as GetEmailsByMailingListIdAjaxResponse;

  const {audiences} = response;
  const updatedAudiences = audiences.map(populateAudienceCustomFieldsFromAudienceResponse);
  return {...response, audiences: updatedAudiences};
});

export const duplicateMailingList = createAsyncThunk('duplicateMailingList', async (data: DuplicateMailingListParams): Promise<DuplicateMailingListResponse> => {
  showLoading('duplicatingMailingList', {text: 'Duplicating audience'});
  const response = (await window.PMW.readLocal('emailmarketing/duplicateMailingListV2', data)) as DuplicateMailingListResponse;
  hideLoading('duplicatingMailingList');
  return response;
});

export const deleteEmail = createAsyncThunk('deleteEmail', async (data: DeleteAudienceParams) => {
  return window.PMW.writeLocal('emailmarketing/deleteEmailFromMailingList', {
    idMailingList: data.idMailingList,
    email: data.audience.email,
  });
});

export const mailingListViewed = createAsyncThunk('mailingListViewed', async (data: MailingListViewedParams) => {
  return window.PMW.writeLocal('emailmarketing/updateMailingListLastViewed', {
    idMailingList: data.idMailingList,
  });
});

export const updateAllContactFieldsForMailingListAudience = createAsyncThunk(
  'updatePhoneNumberForMailingListAudience',
  async (data: {idAudience: number; audienceData: Partial<Audience>}) => {
    return window.PMW.writeLocal('emailmarketing/updateAllContactFieldsForMailingListAudience', {
      idAudience: data.idAudience,
      audienceData: data.audienceData,
    });
  }
);

export const updateInfoForMailingListAudience = createAsyncThunk('updateInfoForMailingListAudience', async (data: UpdateInfoForAudienceInMailingListParams) => {
  return window.PMW.writeLocal('emailmarketing/updateInfoForMailingListAudience', {
    idAudience: data.idAudience,
    fullName: data.fullName,
    phoneNumber: data.phoneNumber,
  });
});

export const updateContactFieldForMailingListAudience = createAsyncThunk(
  'updateContactFieldForMailingListAudience',
  async (data: UpdateContactFieldForAudienceInMailingListParams) => {
    return window.PMW.writeLocal('emailmarketing/updateContactFieldForMailingListAudience', {
      idAudience: data.idAudience,
      field: data.field, // The dynamic field being updated (e.g., 'fullName', 'phoneNumber', etc.)
      value: data.value, // The corresponding value for the field (e.g., the actual name or phone number)
    });
  }
);

export const updateMailingListName = createAsyncThunk('updateMailingListName', async (data: UpdateMailingListNameParams, {getState, dispatch, rejectWithValue}) => {
  const {grids} = getState() as {grids: StateProps};

  const grid = grids.gridsHashmap[GRID_ID];
  let response;

  try {
    updateMailingListNameInGrid(dispatch, grid, {
      itemId: castIdToStringForBaseGrid(data.idMailingList),
      newName: data.newName,
    });

    response = await window.PMW.writeLocal('emailmarketing/updateMailingListName', {
      idMailingList: data.idMailingList,
      name: data.newName,
    });
  } catch (err) {
    updateMailingListNameInGrid(dispatch, grid, {
      itemId: castIdToStringForBaseGrid(data.idMailingList),
      newName: data.currentName,
    });
    return rejectWithValue(err);
  }

  return response;
});

export const deleteMailingList = createAsyncThunk('deleteMailingList', async (data: GetEmailsForCSVParams, {getState, dispatch, rejectWithValue}) => {
  const {grids} = getState() as {grids: StateProps};

  const grid = grids.gridsHashmap[GRID_ID];
  showLoading('delete-list');

  try {
    const response = await window.PMW.writeLocal('emailmarketing/deleteMailingList', data);
    const gridItem = findGridItem(grid, data.idMailingList);

    if (gridItem) {
      dispatch(
        deleteGridItems({
          gridId: GRID_ID,
          gridSelectionGroupId: GRID_ID,
          itemIdsToDelete: [gridItem.id],
        })
      );
    }

    hideLoading('delete-list');
    return response;
  } catch (err) {
    rejectWithValue(err);
    hideLoading('delete-list');
  }
});

export const getUserMailingListsForSignUpForms = createAsyncThunk('getUserMailingListsForSignUpForms', async (): Promise<CreateSignupFormMailingListsResponse> => {
  return (await window.PMW.readLocal('formmaker/getCreateSignupFormMailingLists')) as CreateSignupFormMailingListsResponse;
});

const updateMailingListNameInGrid = (dispatch: any, grid: GridStorageTypes, data: UpdateMailingListNameInGridParams) => {
  if (grid) {
    dispatch(
      updateGridItem({
        gridId: GRID_ID,
        itemId: data.itemId,
        changes: {
          name: data.newName,
        },
      })
    );
  }
};

import {createSlice} from '@reduxjs/toolkit';
import {initEmailMarketingCampaignPerformance, removeInvalidEmailsForCampaign, removeInvalidEmailsForMailingList} from "./email-marketing-performance-thunk.js";
import {LOADING_STATES, INPUT_SELECTED_ITEM_ID_INDEX, updateWindowLocationHash, getFirstSelectedItemId} from "@Libraries/email-marketing-campaigns-library.js";

const initialState = {
    performanceLoadingState: LOADING_STATES.NOT_LOADED,
    campaigns: [],
    campaignsWithMetricsAvailable: [],
    campaignsWithMetricsNotAvailable: [],
    campaignsWithMetricsCollecting: [],
    mailingLists: [],
    mailingListsWithAvailableMetrics: [],
    mailingListsWithMetricsNotAvailable: [],
    mailingListsWithMetricsCollecting: [],
    campaignMetrics: [],
    mailingListMetrics: [],
    selectedItemIds: [],
    itemType: '',
    isNoCampaignCreated: false,
    isNoCampaignPublished: false,
    isCollectingData: false,
    isNoMailingListCreated: false,
    isNotCollectingData: false
};

const emailCampaignsPerformanceSlice = createSlice({
    name: 'emailCampaignsPerformance',
    initialState,
    reducers: {
        updateItemType: (state, action) => {
            const itemType = action.payload.itemType, selectedItemIds = [];
            state.itemType = itemType;
            state.selectedItemIds = selectedItemIds;
            state.performanceLoadingState = LOADING_STATES.NOT_LOADED;
            updateWindowLocationHash(itemType, selectedItemIds);
        },
        updateFirstId: (state, action) => {
            state.selectedItemIds[INPUT_SELECTED_ITEM_ID_INDEX.FIRST_ID] = action.payload.firstId;
            state.performanceLoadingState = LOADING_STATES.NOT_LOADED;
            updateWindowLocationHash(state.itemType, state.selectedItemIds);
        },
        updateSecondId: (state, action) => {
            state.selectedItemIds[INPUT_SELECTED_ITEM_ID_INDEX.SECOND_ID] = action.payload.secondId;
            state.performanceLoadingState = LOADING_STATES.NOT_LOADED;
            updateWindowLocationHash(state.itemType, state.selectedItemIds);
        },
        updateLoadingState: (state, action) => {
            state.performanceLoadingState = action.payload;
        }
    },

    extraReducers: {
        [initEmailMarketingCampaignPerformance.pending]: (state) => {
            state.performanceLoadingState = LOADING_STATES.LOADING;
        },

        [initEmailMarketingCampaignPerformance.fulfilled]: (state, {payload}) => {
            state.campaigns = payload.campaigns;
            state.campaignsWithMetricsAvailable = payload.campaignsWithMetricsAvailable;
            state.campaignsWithMetricsNotAvailable = payload.campaignsWithMetricsNotAvailable;
            state.campaignsWithMetricsCollecting = payload.campaignsWithMetricsCollecting;
            state.campaignMetrics = payload.campaignMetrics;
            state.mailingLists = payload.mailingLists;
            state.mailingListsWithAvailableMetrics = payload.mailingListsWithAvailableMetrics;
            state.mailingListsWithMetricsNotAvailable = payload.mailingListsWithMetricsNotAvailable;
            state.mailingListsWithMetricsCollecting = payload.mailingListsWithMetricsCollecting;
            state.mailingListMetrics = payload.mailingListMetrics;
            state.itemType = payload.itemType;
            state.selectedItemIds = payload.selectedItemIds;
            state.isNoCampaignCreated = payload.isNoCampaignCreated;
            state.isNoCampaignPublished = payload.isNoCampaignPublished;
            state.isCollectingData = payload.isCollectingData;
            state.isNoMailingListCreated = payload.isNoMailingListCreated;
            state.isNotCollectingData = payload.isNotCollectingData;
            state.performanceLoadingState = LOADING_STATES.LOADED;
            updateWindowLocationHash(state.itemType, state.selectedItemIds);
        },

        [removeInvalidEmailsForCampaign.pending]: (state) => {
            state.performanceLoadingState = LOADING_STATES.LOADING;
        },

        [removeInvalidEmailsForCampaign.fulfilled]: (state, {meta}) => {
            const {emails} = meta.arg;
            state.performanceLoadingState = LOADING_STATES.LOADED;

            emails.forEach(email => {
                state.campaignMetrics[getFirstSelectedItemId(state.selectedItemIds)]['details'][email].isDeleted = true;
            })
        },

        [removeInvalidEmailsForMailingList.pending]: (state) => {
            state.performanceLoadingState = LOADING_STATES.LOADING;
        },

        [removeInvalidEmailsForMailingList.fulfilled]: (state) => {
            state.performanceLoadingState = LOADING_STATES.LOADED;
            state.mailingListMetrics[getFirstSelectedItemId(state.selectedItemIds)]['insights'][payload].isDeleted = true;
        }
    }
});

export const {updateItemType, updateFirstId, updateSecondId, updateLoadingState} = emailCampaignsPerformanceSlice.actions;
export const emailMarketingCampaignsPerformanceReducer = emailCampaignsPerformanceSlice.reducer;

export const resetPerformanceLoadingState = () => {
    PMW.redux.store.dispatch(updateLoadingState(LOADING_STATES.NOT_LOADED));
}

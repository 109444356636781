import {createContext} from 'react';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import type {AddMailingListPanelContainerQuery} from '@Components/mailing-list-wizard/mailing-list-wizard.types';

export const AddMailingListPanelContainerQueriesContext = createContext<AddMailingListPanelContainerQuery>({
  isSmallScreen: undefined,
  isLargeScreen: undefined,
});

export const containerQuery: ContainerQueryObject = {
  small: {
    minWidth: 0,
    maxWidth: 768,
  },
  large: {
    minWidth: 769,
  },
};

import {useEffect, useRef} from "react";

/**
 * Runs a useEffect only when anything inside the dependencies change.
 * It does not run on initial render like a regular useEffect.
 * Useful for cases where we do not want to run the effect on first render, but want to run it on subsequent updates.
 * The React Class component equivalent would be: componentDidUpdate
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 * @param {function} useEffectCallback
 * @param {Array} dependencies
 */
const useDidUpdateEffect = (useEffectCallback, dependencies) => {
    const hasFirstRenderCompleted = useRef(true);

    useEffect(() => {
        if (hasFirstRenderCompleted.current) {
            hasFirstRenderCompleted.current = false;
            return;
        }

        return useEffectCallback();
    }, dependencies)
}

export default useDidUpdateEffect;
export interface SocialMediaProfileLinksProps {
  socialMediaProfileLinks: Record<SocialMediaProfile, string>;
  onInputChangeCallback: (socialMediaProfileName: SocialMediaProfile, url: string) => void;
  errorStates?: Record<SocialMediaProfile, boolean>;
}

export enum SocialMediaProfile {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  X = 'x',
  YOUTUBE = 'youtube-channel',
  TIKTOK = 'tiktok-profile',
  PINTEREST = 'pinterest',
  LINKEDIN = 'linkedin',
}

export enum SocialMediaProfileDisplayName {
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  X = 'X',
  YOUTUBE = 'YouTube',
  TIKTOK = 'TikTok',
  PINTEREST = 'Pinterest',
  LINKEDIN = 'LinkedIn',
}

export interface SocialMediaLinksValidationResult {
  isValid: boolean;
  formattedUrls: Record<SocialMediaProfile, string>;
  errorMap: Record<SocialMediaProfile, boolean>;
}
